export const columns = [
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'registration_date',
  },

  {
    title: 'Kode SPSI',
    dataIndex: 'code',
  },
  {
    title: 'Nama',
    dataIndex: 'name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
  },
  {
    title: 'No Telepon',
    dataIndex: 'phone',
  },
  {
    title: 'Wilayah',
    dataIndex: 'wilayah',
  },
  {
    title: 'Kabupaten',
    dataIndex: 'kabupaten',
  },

  {
    title: 'Provinsi',
    dataIndex: 'provinsi',
  },
  {
    title: 'Region',
    dataIndex: 'region',
  },
  // {
  //   title: 'Area',
  //   dataIndex: 'area',
  // },
  {
    title: 'Status',
    slots: { customRender: 'status' },
  },
  {
    title: 'Tanggal Nonaktif',
    dataIndex: 'nonaktif_at',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

