export default {
  code: [
    {
      required: true,
      message: 'Kode Tidak Boleh Kosong',
    },
    {
      max: 9,
      min: 9,
      message: 'Kode harus 9 karakter.',
    },
  ],
  registration_at: [
    { required: true,
      message: 'Tanggal Registrasi tidak boleh kosong!',
    },
  ],
  name: [
    { required: true,
      message: 'Nama SPSI tidak boleh kosong!',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Nomor Telepon tidak boleh kosong!',
    },
    {
      min: 10,
      message: 'Minimal nomor telepon 10 angka',
    },
    {
      max: 12,
      message: 'Maximal nomor telepon 12 angka',
    },
    {
      pattern: /^[0-9]*$/,
      message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
    },
  ],
  wilayah_id: [
    {
      required: true,
      message: 'Kabupaten/Kota tidak boleh kosong!',
    },
  ],
  address: [
    {
      required: true,
      message: 'Alamat tidak boleh kosong!',
    },
  ],
   active: [
    {
      required: true,
      type: 'boolean',
      message: 'Status tidak boleh kosong!',
    },
  ],
}
