export const data = [
  {
    id: 1,
    registration_at: '2023-06-20 16:25',
    id_sps: '112233',
    name: 'Budi Wibo',
    address: 'JL. Nairo Waip',
    phone: '080021232123',
    desa: 'GELITI',
    kecamatan: 'KEWAPA',
    kabupaten: 'SIDOARJO',
    province: 'JAWA TIMUR',
    region: 'JAWA',
    status: 'Aktif',
    nonaktif_at: null,
  },
  {
    id: 2,
    registration_at: '2023-06-20 16:25',
    id_sps: '112233',
    name: 'Budi Wibo',
    address: 'JL. Nairo Waip',
    phone: '080021232123',
    desa: 'GELITI',
    kecamatan: 'KEWAPA',
    kabupaten: 'SIDOARJO',
    province: 'JAWA TIMUR',
    region: 'JAWA',
    status: 'Aktif',
    nonaktif_at: '2023-06-20 16:25',
  },
]


